import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '',
    drawer: null,
    city: null,
    area: null,
    region: null,
    token: '',
    username: '',
    is_staff: false,
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    setCity(state, city) {
      state.city = city;
    },
    setArea(state, area) {
      state.area = area;
    },
    setRegion(state, region) {
      state.region = region;
    },
    setToken(state, data) {
      state.token = data.token;
    },
    setUsername(state, data) {
      state.username = data.username;
    },
    setIsStaff(state, data) {
      state.is_staff = data.is_staff;
      console.log("state "+state.is_staff)
    },
  },
  actions: {
    submitToken({ commit }, data) {
      commit("setToken", data);
      commit("setUsername", data);
      commit("setIsStaff", data);
    },
  },
})
