function roundDecimal(val, precision) {
  return (
    Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) /
    Math.pow(10, precision || 0)
  );
}

/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
Number.prototype.format = function (n, x, s, c) {
  var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")";
  var num = roundDecimal(this, n).toFixed(Math.max(0, ~~n));

  return (c ? num.replace(".", c) : num).replace(
    new RegExp(re, "g"),
    "$&" + (s || ",")
  );
};

var CommonUtil = {
  isEmpty(val) {
    // test results
    //---------------
    // []        true, empty array
    // {}        true, empty object
    // null      true
    // undefined true
    // ""        true, empty string
    // ''        true, empty string
    // 0         false, number
    // true      false, boolean
    // false     false, boolean
    // Date      false
    // function  false

    if (val === undefined) return true;

    if (
      typeof val == "function" ||
      typeof val == "number" ||
      typeof val == "boolean" ||
      Object.prototype.toString.call(val) === "[object Date]"
    )
      return false;

    if (val == null || val.length === 0)
      // null or 0 length array
      return true;

    if (typeof val == "object") {
      // empty object

      var r = true;

      for (var f in val) r = false;

      return r;
    }

    return false;
  },
  getText(val, items) {
    if (val === undefined || val === null) {
      return ''
    }
    let result = items.find(obj => {
      return obj.value == val;
    });
    if (result) {
      return result.text;
    }
    return val;
  },
  getRange(val) {
    if (val.indexOf("-") != -1) {
      return val.replace("-", ",");
    } else {
      return val + "," + val;
    }
  },
  getNumber(val) {
    let number = Number(val);
    if (isNaN(number)) {
      return val;
    }
    return number.format(2, 3, ",", ".");
  },
  setValue(val, item, index) {
    item[index] = val;
  },
  getPercent(val) {
    let number = Number(val);
    if (isNaN(number)) {
      return val;
    }
    return number * 100;
  },
  getSortParas(sortBy, sortDesc) {
    const sortArray = []
    let item = ''
    for (let i = 0; i < sortBy.length; i += 1) {
      const sortStr = sortBy[i].replace('.', '__')
      if (sortDesc[i]) {
        item = sortStr
      } else {
        item = `-${sortStr}`
      }
      sortArray.push(item)
    }
    console.log(sortArray)

    return sortArray.join()
  },
};


export default CommonUtil;
