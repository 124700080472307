import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/dashboard/pages/Login'),
        },
        {
          name: 'Index',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Spider Tables',
          path: 'tables/spider-tables',
          component: () => import('@/views/dashboard/tables/SpiderTable'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'HN Account',
          path: 'tables/hnaccount',
          component: () => import('@/views/dashboard/tables/HNAccount'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Schedule',
          path: 'tables/schedule',
          component: () => import('@/views/dashboard/tables/ScheduleTable'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Schedule_v3',
          path: 'tables/schedule_v3',
          component: () => import('@/views/dashboard/tables/ScheduleTable_V3'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'ScheduleReport',
          path: 'tables/schedule-report',
          component: () => import('@/views/dashboard/tables/ScheduleReportTable'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'ScheduleVerify',
          path: 'tables/schedule-verify',
          component: () => import('@/views/dashboard/tables/ScheduleTableVerify'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Mission',
          path: 'tables/mission',
          component: () => import('@/views/dashboard/tables/MissionTable'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'TaskPool',
          path: 'tables/taskpool',
          component: () => import('@/views/dashboard/tables/TaskPoolTable'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Land Profile',
          path: 'tables/landprofile',
          component: () => import('@/views/dashboard/tables/LandProfile'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Building Profile',
          path: 'tables/buildings',
          component: () => import('@/views/dashboard/tables/BuildingProfile'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Pack',
          path: 'tables/packs',
          component: () => import('@/views/dashboard/tables/PackTable'),
          meta: {
            requiresAuth: true
          }
        },
        // Maps
        {
          name: 'Open Maps',
          path: 'maps/open-maps',
          component: () => import('@/views/dashboard/maps/OpenMaps'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Database Checker',
          path: 'tables/database-checker',
          component: () => import('@/views/dashboard/tables/DatabaseChecker'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Specail Character',
          path: 'tables/SpecailChar',
          component: () => import('@/views/dashboard/tables/SpecailChar'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Capthca ',
          path: 'tables/capthca',
          component: () => import('@/views/dashboard/tables/CapthcaTable'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Customer Mission',
          path: 'tables/CustomerMission',
          component: () => import('@/views/dashboard/tables/CustomerMission'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'LandSummary',
          path: 'tables/LandSummary',
          component: () => import('@/views/dashboard/tables/LandSummary'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'BuildingsSummary',
          path: 'tables/BuildingsSummary',
          component: () => import('@/views/dashboard/tables/BuildingsSummary'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'LandCheck',
          path: 'tables/LandCheck',
          component: () => import('@/views/dashboard/tables/LandCheck'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'configuration',
          path: 'pages/configuration',
          component: () => import('@/views/dashboard/pages/Configuration'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'map',
          path: 'pages/map',
          component: () => import('@/views/dashboard/tables/Map'),

        },
      ],
    },
  ],
})
