// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueNativeSock from 'vue-native-websocket'
import axios from 'axios'
import VueAxios from 'vue-axios'
import CommonUtil from "@/utils/commonutil.js";
import "./filters.js";
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

Vue.use(VueLodash, { lodash: lodash })
// Vue.use(VueNativeSock, process.env.VUE_APP_WEBSOCKET_URL, {
//   format: 'json',
//   reconnection: true, // (Boolean) whether to reconnect automatically (false)
//   reconnectionDelay: 10000, // (Number) how long to initially wait before attempting a new (1000)
// })
Vue.use(require('vue-moment'));
Vue.use(VueAxios, axios);
Vue.prototype.$common_util = CommonUtil;
Vue.config.productionTip = false
// update icon
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


//  handle 401 and redirect to login page
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.token) {
      next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})


axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response) {
      if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
        router.push({ name: 'Login' }).catch(() => { })
      }
      return Promise.reject(error);
    } else {
      // 成功發出請求但沒收到 response
      return Promise.reject(error);
    }
  }
);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
